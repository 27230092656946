.container {
    display: flex;
    font-size: 13px;
    font-weight: normal;
    align-items: center;
}

.is-large {
    composes: container;
    font-size: 15px;
    font-weight: normal;
}

.container label {
    cursor: pointer;
    user-select: none;
    line-height: 1;
}

.container input:disabled + label {
    cursor: default;
    color: var(--grey-lt);
}

.checkbox {
    margin-right: 8px;
    cursor: pointer;
}

.is-top-aligned {
    align-items: flex-start;
}

.is-large input[type='checkbox'] {
    opacity: 0;
    height: 18px;
    width: 18px;
    margin-right: 0;
    flex-shrink: 0;
}

.is-large label {
    position: relative;
    padding-left: 6px;
    line-height: 18px;
}

.is-large label::before {
    content: '';
    position: absolute;
    display: inline-block;
    height: 16px;
    width: 16px;
    border: 1px solid var(--grey-lt);
    border-radius: 2px;
    top: 0;
    left: -18px;
}

.is-large label::after {
    content: '';
    position: absolute;
    display: inline-block;
    height: 4px;
    width: 8px;
    border-left: 2px solid #fff;
    border-bottom: 2px solid #fff;
    transform: rotate(-45deg);
    left: -14px;
    top: 5px;
}

/* Disabled styles */
.is-large input[type='checkbox']:disabled + label::before {
    background-color: #fff;
    border-color: var(--off-white-dk);
}
.is-large input[type='checkbox']:disabled + label::after {
    border-color: var(--off-white-dk);
}

/* Hide the checkmark by default */
.is-large input[type='checkbox'] + label::after {
    content: none;
}

.is-large input[type='checkbox'] + label::before {
    background-color: #fff;
}

/* Unhide the checkmark on the checked state */
.is-large input[type='checkbox']:checked + label::after {
    content: '';
}

.is-large input[type='checkbox']:checked:not(:disabled) + label::before {
    background-color: var(--blue);
    border-color: var(--blue);
}

/* Adding focus styles on the outer-box of the fake checkbox */
.is-large input[type='checkbox']:focus + label::before {
    outline: rgb(59, 153, 252) auto 5px;
}

.is-large.has-error input[type='checkbox'] + label::before {
    border-color: var(--rose);
}
