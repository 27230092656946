.main-content {
    position: absolute;
    top: 50%;
    left: 50%;
    width: calc(100% - 48px);
    transform: translate(-50%, -50%);
}

.main-content p {
    font-size: var(--font-normal);
    line-height: 1.4;
}

.main-content a {
    color: var(--blue);
    cursor: pointer;
}

.main-content a:hover,
.main-content a:focus {
    color: var(--blue-dk);
    text-decoration: underline;
}
