.question-slide {
    width: 400px;
    display: flex;
    flex-direction: column;
    animation: fadeAndSlideIn 0.5s;
    user-select: none;
}

.question-slide--two-columns {
    composes: question-slide;
    width: 816px; /* 400px each column, 16px gap */
}

.question-slide--wide {
    width: 660px;
    margin: 100px auto;
}

.question-slide > :first-child {
    margin-bottom: 4px;
}

.question-slide > :nth-child(2) {
    margin-bottom: 32px;
}

/* No two column */
@media (max-width: 1344px) {
    .question-slide--two-columns {
        width: 400px;
    }
}

/* No image column */
@media (max-width: 928px) {
    .question-slide {
        width: unset;
        max-width: 400px;
    }

    .question-slide--two-columns {
        width: unset;
        max-width: 400px;
    }
}

@keyframes fadeAndSlideIn {
    from {
        opacity: 0;
        transform: translateX(64px);
    }
    to {
        opacity: 1;
        transform: translateX(0);
    }
}

.info-message {
    padding: 0 10px 10px 10px;
}
