.fields {
    margin-top: 32px;
}

.button {
    composes: space-above from '../../auth-shared.css';
}

.submit {
    margin-bottom: 8px;
}

.success {
    color: var(--color-success);
    font-size: var(--font-normal);
}
