.space-above {
    margin-top: 24px;
}

.space-below {
    margin-bottom: 16px;
}

.footer-container {
    composes: space-above;
    display: flex;
    justify-content: center;
    font-size: var(--font-normal);
}
