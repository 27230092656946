.join-your-team strong {
    color: var(--grey-dk);
}

.join-your-team p {
    margin-bottom: 64px;
}

.contact-support {
    margin-top: 8px;
    margin-bottom: 32px;
    text-align: center;
}

.success {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 41px;
    width: 100%;
    text-align: center;
    color: var(--green);
    font-size: var(--font-normal);
}

.different-address-container {
    composes: footer-container from '../auth-shared.css';
}
