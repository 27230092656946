.header-bar {
    height: 64px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 16px;
    box-sizing: border-box;

    background-color: var(--off-white);
    font-size: 15px;
    color: var(--grey-dk);
}

.title-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-grow: 1;
}

.title-container h1 {
    font-size: var(--font-big);
    color: var(--grey-dk);
    line-height: 23px;
    font-weight: normal;
}

.subtitle-container {
    font-size: 12px;
    max-width: 200px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.button {
    position: absolute;
    z-index: 10;
}

.close-button {
    composes: button;
    left: 4px; /* Button has 12px of padding, this makes it 16px */
}

.save-button {
    composes: button;
    right: 4px; /* Button has 12px of padding, this makes it 16px */
}

.header-bar .close-button :global(.svg-close svg) {
    fill: var(--grey);
}

.header-bar .close-button :global(.svg-close:hover svg),
.header-bar .close-button:focus :global(.svg-close svg) {
    fill: var(--grey-dk);
}
