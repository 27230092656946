/* The Google button varies in size as it loads, this prevents it from shifting others */
.google-oauth-button-container {
    height: 40px;
    position: relative;
}

.google-oauth-button-container > :first-child {
    position: absolute;
    width: 100%;
}

.loading-placeholder {
    height: 38px;
    box-sizing: border-box;
    padding: 0 4px 0 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: var(--grey-lt);
}
