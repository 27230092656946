.company-logo {
    max-width: 60px;
    max-height: 51px;
    width: auto;
    height: auto;
    margin-right: 16px;
    align-self: center;
    display: flex;
    position: relative;
}

.company-logo > img {
    max-height: 100%;
    max-width: 100%;
    object-fit: contain;
}
