.button {
    composes: space-above from '../auth-shared.css';
}

.button > button {
    /* Matches textfield */
    font-size: 16px;
    height: 51px;
}

.link-button > div {
    margin-top: 0;
}

.submit {
    margin-bottom: 8px;
}

.label {
    composes: space-above from '../auth-shared.css';
    display: flex;
    justify-content: space-between;
}

.login-container {
    composes: footer-container from '../auth-shared.css';
}

.login-container > a {
    margin-left: 4px;
}

.resend {
    display: flex;
    padding-top: 4px;
}

.resend button {
    padding: 0;
}

.recovery-button-container {
    margin-top: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
}
