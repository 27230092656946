@value list-width: 250px;

.list-item {
    display: flex;
    align-items: center;
    padding: 4px 8px;
    width: list-width;
    border-radius: 4px;
}

.list-item:hover {
    background-color: var(--off-white);
}

.list-item label {
    width: calc(list-width - 16px);
}
