.button,
.space-above {
    composes: space-above from '../auth-shared.css';
}

.button-loading > button {
    padding: 11px 24px !important;
}

.button:first-child > button {
    padding: 10px 24px !important;
}

.button:last-child > button {
    padding: 17px 24px !important;
}

.button-contents-container {
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
}

.button-contents-container > svg {
    margin-right: 8px;
}

.loading-icon-container {
    margin-left: 8px;
}

.below-button-message {
    margin-top: 16px;
}

.below-button-message > button {
    padding: 4px 0;
}

.seperator {
    composes: space-above from '../auth-shared.css';
    composes: space-below from '../auth-shared.css';
}

.login-container {
    composes: footer-container from '../auth-shared.css';
}

.login-container > a {
    margin-left: 4px;
}
