.slide-image {
    width: 400px;
    animation: fadein 0.5s;
}

.slide-image--small {
    width: 170px;
    animation: fadein 0.5s;
}

@keyframes fadein {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
