/*
Our min-width for getting rid of the image on this view is 700px, but it seems
we need a single pixel less to make the button disappear at the same point
as our image
*/
@media (max-width: 699px) {
    .back-to-nutshell-container {
        display: none;
    }
}
