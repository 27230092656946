.container {
    max-height: 244px; /* The height of the calendar, plus padding for the border */
    width: 500px;
    display: flex;

    background-color: white;
    border: 2px solid var(--off-white-dk);
    border-top-color: var(--orange-lt);
    box-sizing: border-box;
}

.container-time {
    composes: container;
    width: 630px;
}

.type-creator-modal {
}

.type-popover-picker {
    border: 0;
    border-top: 2px solid var(--orange-lt);
}

.calendar-wrapper {
    width: 240px;
}
