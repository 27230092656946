.tiny-introduction-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    height: 100%;
}

.progress-bar-container {
    width: 60px;
    position: fixed; /* Should stay on top when scrolling */
    top: 32px;
    right: 32px;
    background-color: var(--off-white);
    border-radius: 3px;
    overflow: hidden;
}

.small-screen-back-button {
    display: none;
}

/* No image column */
@media (max-width: 928px) {
    .back-button {
        display: none;
    }

    .progress-bar-container {
        display: none;
    }

    .small-screen-back-button {
        display: inline;
    }
}
