.container {
    display: flex;
    flex-direction: row;
    width: fit-content;
    height: 100%;
}

.button svg {
    fill: var(--grey);
    padding-right: 4px !important;
}

.button:hover svg {
    fill: var(--grey-dk);
}

.button {
    color: var(--blue);
    padding: 0 12px;
    width: 100%;
    height: 100%;
}

.button:hover {
    color: var(--navy-dk);
}

.button--background {
    composes: button;
    border: 1px var(--off-white-dk) solid;
    background-color: #fff;
}

.button--background:hover {
    background-color: var(--off-white);
}
