.verify-email h1 {
    font-weight: normal;
    margin-bottom: 48px;
}

.verify-email strong {
    color: var(--grey-dk);
}

.resend-link {
    margin-top: 8px;
    margin-bottom: 32px;
    text-align: left;
}

.loading-icon {
    fill: var(--off-white-dk);
    text-align: center;
}

.pin-domain-options {
    text-align: center;
    margin-top: 48px;
}

.pin-domain-options > *:first-child {
    margin-bottom: 16px;
}

/*
Our min-width for getting rid of the image on this view is 700px, but it seems
we need a single pixel less to make the button disappear at the same point
as our image
*/
@media (max-width: 699px) {
    .back-to-nutshell-container {
        display: none;
    }
}
