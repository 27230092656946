.browser-list {
    display: flex;
    justify-content: space-between;
}

.browser-list > a {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 8px;
}

.browser-list > a:hover,
.browser-list > a:focus {
    background-color: var(--off-white-md);
}

.browser-list img {
    height: 100px;
    margin-bottom: 8px;
}
