.fields {
    margin-top: 32px;
}

.button {
    composes: space-above from '../../auth-shared.css';
}

.submit {
    margin-bottom: 8px;
}
