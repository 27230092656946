.popover-form {
    position: relative;
    padding: 16px;
}

.popover-form form {
    width: 100%;
}

.form-body {
    margin: 32px 0;
}

.top {
    display: flex;
    justify-content: space-between;
    min-width: 0;
    /* Close button has 10px horizontal padding, this aligns icon with submit button */
    width: calc(100% + 10px);
}

.top > *:last-child {
    flex-shrink: 0;
}

.title {
    padding-right: 16px;
}

.title--no-wrap {
    min-width: 0;
}

.title--no-wrap > * {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.buttons {
    width: 100%;
    display: flex;
    justify-content: space-between;
    position: absolute;
    bottom: 0;
    right: 0;
    padding: 16px;
    box-sizing: border-box;
}

.buttons > *:first-child {
    /* Cancel button has 12px of left padding */
    margin-left: -12px;
}
